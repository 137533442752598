import * as React from "react"
import 'bootstrap/dist/css/bootstrap.css'
import About from "../components/About"
import '../styles/fonts.css'
import Layout from "../layouts/Layout"

const AboutUsPage = () => {
  return (
    <Layout>
        <About/>
    </Layout>
)
}

export default AboutUsPage

export const Head = () => <title>Verse Estate Corporate - About Us</title>
